import React, { useState } from 'react'
import BoxSave from "../../../assets/images/icons/boxSave.svg"
const Plan = () => {
  const [selectedBox, setSelectedBox] = useState(null);

  const handleBoxClick = (boxId) => {
    setSelectedBox(boxId);
  };
  return (
    <div className="plan">
      <div className="contanier">
        <div className="row">
          
          <div className="planComp">
            <h2>My Plan</h2>
            <div className="planBox">
      <div 
        className={`firstBox ${selectedBox === 1 ? 'selected' : ''}`} 
        onClick={() => handleBoxClick(1)}
      >
        <div className="top">
          <p>Personal</p>
          <span>Save -16% on bill yearly</span>
        </div>
        <p className='price'>$39</p>
        <div className="bottom">
          <span>Next payment 24 February 2024</span>
          {selectedBox === 1 ? <div className="circle"><img src={BoxSave} alt="" /></div> : <input type="checkbox" />}
        </div>
      </div>
      
      <div 
        className={`firstBox ${selectedBox === 2 ? 'selected' : ''}`} 
        onClick={() => handleBoxClick(2)}
      >
        <div className="top">
          <p>Personal</p>
          <span>Save -16% on bill yearly</span>
        </div>
        <p className='price'>$39</p>
        <div className="bottom">
          <span>Next payment 24 February 2024</span>
          {selectedBox === 2 ? <div className="circle"><img src={BoxSave} alt="" /></div> : <input type="checkbox" />}
        </div>
      </div>
    </div>
          </div>
          <div className="updateBtn">
            <button>Update plan</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Plan