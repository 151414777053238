export const generateYouTubeEmbedUrlId = (url) => {
    let regex = /[?&]v=([^#\&\?]*).*/;
    let match = url.match(regex);
    if (!match) {
      regex = /youtu\.be\/([^#\&\?]*).*/;
      match = url.match(regex);
    }
    if (match && match[1]) {
      const videoId = match[1];
      return videoId;
    } else {
      return null;
    }
  };