import { createContext, useContext, useEffect, useState } from "react";

// Context
import { LoginContext } from "./Auth";

// Swal
import { confirmSwal } from "./swal";

// Translation
import { useTranslation } from "react-i18next";
import {
  settingsPages,
} from "../constants/constants";

export const MainContext = createContext();

export const AppContext = ({ children }) => {
  // Translation
  const { t, i18n } = useTranslation();

  // Global states
  const { user } = useContext(LoginContext);

  // States
  const [step, setStep] = useState(1);
  const [settingsComponent, setSettingsComponent] = useState(
    settingsPages.plan
  );
  const [settingsServiceComponent, setSettingsServiceComponent] = useState('');
  const [forgotStep, setForgotStep] = useState(1);
  const [phone, setPhone] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [businessPlan, setBusinessPlan] = useState(false);
  const [selectedPack, setSelectedPack] = useState({
    price: 0,
    discountPercent: 0,
  });
  const [openModal, setOpenModal] = useState(false);
  const [langModalVivible, setLangModalVisible] = useState(false);
  const [mobileLangVisivle, setMobileLangVisivle] = useState(false);

  const [addBoxOpen, setAddBoxOpen] = useState(false);
  const [profileModalIsOpen, setProfileModalIsOpen] = useState(false);
  const [searchToBoxModalOpen, setSearchToBoxModalOpen] = useState(false);
  const [editBoxOpen, setEditBoxOpen] = useState(false);
  const [dashboardPage, setDashboardPage] = useState("boxes");
  const [selectedBox, setSelectedBox] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [fakeUpdate, setFakeUpdate] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [sharedProfileModal, setSharedProfileModal] = useState(false);
  const [editBoxId, setEditBoxId] = useState("");
  const [registerSuccessMsg, setRegisterSuccessMsg] = useState("");
  const [hasCardOrdered, setHasCardOrdered] = useState(false);

  const [mySelfProfilView, setMySelfProfilView] = useState(false);

  const buyPacket = (data) => {
    setSelectedPack(data);
    setOpenModal(true);
  };

  const [profilEditModalVisible, setProfilEditModalVisible] = useState(false);
  const [profilEditModalType, setProfilEditModalType] = useState(null);
  const [profileData, setProfileData] = useState(null);
  // Set Lang
  useEffect(() => {
    const detectLng = async () => {
      if (i18n.language === "tr") {
        await i18n.changeLanguage("az");
      }
    };
    detectLng();
  }, [i18n]);

  // Change language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  //  Screen Width  Check for Mobile Lang Modal
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMobileLangVisivle(false);
      } else {
        setMobileLangVisivle(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const removeUserFromBox = (profileID) => {
    confirmSwal(
      t("boxDetails.delete"),
      "",
      t("boxDetails.yes"),
      t("boxDetails.no"),
      () => {},
      () => {
        const updatedBox = selectedBox.profiles.filter(
          (data) => data.id !== profileID
        );
        user.boxes.filter((box) => {
          if (box.id === selectedBox.id) {
            box.profiles = updatedBox;
          }
          return box;
        });
        setFakeUpdate(!fakeUpdate);
      },
      t("confirmModal.grant"),
      t("confirmModal.deny")
    );
  };

  // Export States
  const globalStates = {
    step,
    setStep,
    phone,
    setPhone,
    businessPlan,
    setBusinessPlan,
    selectedPack,
    setSelectedPack,
    openModal,
    setOpenModal,

    // Language Modal Visible
    langModalVivible,
    setLangModalVisible,
    mobileLangVisivle,

    addBoxOpen,
    setAddBoxOpen,
    editBoxOpen,
    setEditBoxOpen,
    dashboardPage,
    setDashboardPage,
    profileModalIsOpen,
    setProfileModalIsOpen,
    selectedBox,
    setSelectedBox,
    boxes,
    setBoxes,
    fakeUpdate,
    setFakeUpdate,
    searchToBoxModalOpen,
    setSearchToBoxModalOpen,
    sideBarOpen,
    setSideBarOpen,
    openMenu,
    setOpenMenu,
    sharedProfileModal,
    setSharedProfileModal,
    editBoxId,
    setEditBoxId,
    registerSuccessMsg,
    setRegisterSuccessMsg,
    forgotStep,
    setForgotStep,

    // Profile
    mySelfProfilView,
    setMySelfProfilView,
    profilEditModalVisible,
    setProfilEditModalVisible,
    profilEditModalType,
    setProfilEditModalType,
    profileData,
    setProfileData,

    //Settings
    settingsComponent,
    setSettingsComponent,

    settingsServiceComponent,
    setSettingsServiceComponent,

    hasCardOrdered,
    setHasCardOrdered,

    // Export functions
    buyPacket,
    removeUserFromBox,
    changeLanguage,
  };

  return (
    <MainContext.Provider value={globalStates}>{children}</MainContext.Provider>
  );
};
