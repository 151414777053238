import React from "react";
import plusIcon from "../../../../assets/images/icons/plus-circle.svg";
import arrowLeft from "../../../../assets/images/icons/arrow-square-left.svg";
import arrowRight from "../../../../assets/images/icons/arrow-square-right.svg";
import threeDotIcon from "../../../../assets/images/icons/more-horizontal.svg";
import profileBgImg from "../../../../assets/images/profile-bakground-image.svg";
import PenIcon from "../../../../assets/images/icons/edit-2.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

const ProfileFeaturedSection = ({
  profileData,
  onAdd,
  onEdit,
  showAddIcon,
  showEditIcon,
  isOwner,
}) => {
  return (
    <div className="profileFeaturedSection">
      <div className="topSide">
        <div className="leftSide">
          <p className="title">Portfel</p>
          {/* {showAddIcon && <div className="addIcon" onClick={() => onAdd()}>
                <img src={plusIcon} alt="plus_icon"/>
                <span>Add new</span>
            </div>} */}
        </div>
        <div className="rightSide">
          {showEditIcon && isOwner && (
            <div className="editIcon" onClick={() => onEdit()}>
              <img src={PenIcon} alt="phone_icon" />
            </div>
          )}
          {/* <div className="paginationIcon">
            <img src={arrowLeft} alt="arrow_left_icon" />
          </div>
          <div className="paginationIcon">
            <img src={arrowRight} alt="arrow_right_icon" />
          </div> */}
        </div>
      </div>
      <div className="bottomSide">
        <Swiper
          slidesPerView={"auto"}
          loop={true}
          spaceBetween={16}
          className="mySwiper"
        >
          {profileData?.featured_links?.map((item) => (
            <SwiperSlide key={item.id}>
              <div className="slideItem">
                <Link to={item.url} className="slideImg">
                  <img src={item.image || profileBgImg} alt={item.title} />
                </Link>
                <div className="info">
                  <div className="title">
                    <p>{item.title}</p>
                    <div className="options">
                      <img src={threeDotIcon} alt="three_dot_icon" />
                    </div>
                  </div>
                  <p className="desc">{item.description}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ProfileFeaturedSection;
