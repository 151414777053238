import React, { useEffect } from "react";
import { preLoaderAnim } from "./animation";
import BucardLogo from "../assets/images/bucard-white-logo.svg";

const PreLoader = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);
  return (
    <div className="preloader">
      {/* <div className="logo">
        <img src={BucardLogo} alt="logo" />
        <span>a xoş gəlmisiniz.</span>
      </div> */}
      <div className="texts-container">
        <span>Şəxsi,</span>
        <span>Biznes,</span>
        <span>Şirkət.</span>
      </div>
    </div>
  );
};

export default PreLoader;
