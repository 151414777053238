import React, { useEffect } from "react";
import { useState, useContext } from "react";
import Loading from "../../../components/Loading";
import DashboardHeader from "../../../components/DashboardHeader";
import MobileNavBar from "../../../components/MobileNavBar";
import SettingSideBar from "./SettingSideBar";
import { MainContext } from "../../../utils/MainContext";
import { settingsPages } from "../../../constants/constants";
import Plan from "./Plan";
import Payments from "./Payments";
import Language from "./Language";
import Security from "./Security";
const Settings = () => {
  const { settingsComponent, setSettingsComponent } = useContext(MainContext);
  const [loading, setLoading] = useState(false);

  return (
    <div className="settings">
      {loading && <Loading />}
      <DashboardHeader />
      <MobileNavBar />
      <div className="contanier">
        <div className="row">
          <div className="settingsComponents">
            <SettingSideBar />
            {settingsComponent === settingsPages.plan ? <Plan /> : null}
            {settingsComponent === settingsPages.payments ? <Payments /> : null}
            {settingsComponent === settingsPages.language ? <Language /> : null}
            {settingsComponent === settingsPages.security ? <Security /> : null}
            

          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
