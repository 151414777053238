// Images
import {useContext} from "react";
import {$api} from "../api/api";
import addUser from "../assets/images/icons/add-user.svg";
import userImg from "../assets/images/icons/user-pic.svg";
// Translation
import {useTranslation} from "react-i18next";
import {LoginContext} from "../utils/Auth";

const SharedUserCard = ({ data, onClick }) => {
  // Translation
  const { t } = useTranslation();
  const { user } = useContext(LoginContext);

  return (
    <div className="sharedUserCard">
      <div
        style={{
          backgroundImage: data?.background_image
            ? `url(${data?.background_image.includes("api.") ? "" : $api("image_url") + '/'}${data?.background_image})`
            : "",
        }}
        className="background"
      >
        <div className={`userImg ${data?.avatar && "fullSize"}`}>
          <img
            src={data?.avatar ? `${data?.avatar.includes("api.") ? "" : $api("image_url") + '/'}${data.avatar}` : userImg}
            alt={data?.full_name}
          />
        </div>
      </div>
      <div className="cardInfo">
        <div className="userInfo">
          <a href={`/profile/${data?.username}`} className="userName">
            {data?.full_name}
          </a>
          <p className="boxCount">
            {data?.in_boxes_count} {t("sharedUserCard.boxes")}
          </p>
          <a href={`tel:${data?.phone_number}`} className="phoneNumber">
            {data?.phone_number}
          </a>
        </div>
        <div className="addUserBtn" onClick={onClick}>
          {user?.profile?.id !== data.id && (
            <img src={addUser} alt="add-icon" />
          )}
          <span>
            {user?.profile?.id === data.id
              ? t("sharedUserCard.goProfile")
              : t("sharedUserCard.add")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SharedUserCard;
