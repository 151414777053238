import React, { useEffect, useState } from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";
import infoIcon from "../../../../assets/images/icons/info-circle-gray.svg";
import addCircle from "../../../../assets/images/icons/add-circle.svg";
import AzeFlag from "../../../../assets/images/icons/az-flag.svg";
import RusFlag from "../../../../assets/images/icons/russian.svg";
import EnFlag from "../../../../assets/images/icons/english.svg";
import Arrow from "../../../../assets/images/icons/arrow-down.svg";
import Spinner from "../../../../components/Spinner";
import { toastMsg } from "../../../../utils/swal";
import Select from "react-select";
import * as DictionaryService from "../../../Dictionaries/Service/DictionaryService";

const ProfileLanguageEdit = ({ profileData, closeModal, setProfileData }) => {
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    languages: profileData?.languages || [],
  });
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const getLanguagesList = async () => {
      setLoading(true);
      try {
        const res = await DictionaryService.allLanguages();
        setLanguages(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getLanguagesList();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit(profileInfo);
      setProfileData(res);
      setProfileInfo((prev) => ({
        ...prev,
        languages: res,
      }));
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const addNewInp = () => {
  //   setProfileInfo((prev) => ({
  //     ...prev,
  //     languages: [
  //       ...prev.languages,
  //       {
  //         id: uuidv4(),
  //         name: "",
  //       },
  //     ],
  //   }));
  // };

  const removeInp = async (input) => {
    setLoading(true);
    try {
      if (
        input.id &&
        profileData?.languages?.length === profileInfo?.languages?.length
      ) {
        const res = await ProfileService.deleteProfileDetail({
          field_name: "language",
          id: input.id,
        });
        setProfileData((prev) => ({
          ...prev,
          languages: res?.data?.languages,
        }));
        setProfileInfo((prev) => ({
          ...prev,
          languages: res?.data?.languages,
        }));
      } else {
        setProfileInfo((prev) => ({
          ...prev,
          languages: prev.languages.filter((item) => item.id !== input.id),
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const handleInputChange = (index, value) => {
  //   const updatedLanguages = profileInfo.languages.map((language, i) => {
  //     if (i === index) {
  //       return { ...language, name: value };
  //     }
  //     return language;
  //   });
  //   setProfileInfo((prev) => ({
  //     ...prev,
  //     languages: updatedLanguages,
  //   }));
  // };

  const getFlagImage = (languageName) => {
    switch (languageName) {
      case "Azerbaijan":
        return AzeFlag;
      case "Russian":
        return RusFlag;
      case "English":
        return EnFlag;
      default:
        return null;
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileLangEdit">
      <div className="infoLangEdit">
        <div className="editSkillsArea">
          {profileInfo.languages.map((language) => (
            <button key={language.id} className="skillsCaption">
              <img src={getFlagImage(language.name)} alt="" />
              {language.name}
              <span onClick={() => removeInp(language)}>x</span>
            </button>
          ))}
        </div>
        <div className="addSkillsArea">
          <Select
            placeholder={"Seçin..."}
            closeMenuOnSelect={true}
            escapeClearsValue={true}
            options={languages}
            onChange={(selected) => {
              const existing = profileInfo.languages.find(
                (item) => item.id === selected.value
              );
              if (existing) {
                toastMsg("Bu dil artıq əlavə olunub !", "#ffffff", "#000000");
              } else {
                setProfileInfo((prev) => ({
                  ...prev,
                  languages: [
                    ...prev.languages,
                    {
                      id: selected.value,
                      name: selected.label,
                    },
                  ],
                }));
              }
            }}
            isDisabled={profileInfo.languages.length >= 20}
          />
          {/* {profileInfo.languages.map((language, index) => (
            <div key={language.id} className="socialInp">
              <CustomInput
                placeholder={"Type"}
                rightIcon={Arrow}
                onChange={(value) => handleInputChange(index, value)}
                value={language.name}
              />
              <span onClick={() => removeInp(language)}>x</span>
            </div>
          ))} */}
          <div className="infoSkills">
            <img src={infoIcon} alt="Info Icon" />
            Sadəcə 20 dil əlavə edə bilərsiniz.
          </div>
        </div>
        <div className="btnGroup">
          {/* <div className="addBtn">
            <button className="btnAdd" type="button" onClick={addNewInp}>
              <img src={addCircle} alt="Add Circle" />
              Add new
            </button>
          </div> */}
          <button
            className="btn cancel"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            İmtina
          </button>
          <button className="btn" onClick={onSubmit}>
            Yadda saxla
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileLanguageEdit;
