import React from "react";
import { Link } from "react-router-dom";

const ProfileSocialMedia = ({ profileData }) => {
  return (
    <div className="profileSocialMedia">
      {profileData?.social_media?.map((item) => (
        <Link key={item.id} to={item.url}>
          <img src={item.icon_url} alt={item.type} />
        </Link>
      ))}
    </div>
  );
};

export default ProfileSocialMedia;
