import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddSharedUserModal from "../../../components/AddSharedUserModal";
import DashboardHeader from "../../../components/DashboardHeader";
import Loading from "../../../components/Loading";
import SharedProfileModal from "../../../components/SharedProfileModal";
import SharedUserCard from "../../../components/SharedUserCard";
import { LoginContext } from "../../../utils/Auth";
import * as ProfileService from "../../Profile/Service/ProfileService";

const SharedUserProfile = () => {
  // Router
  const navigate = useNavigate();
  const { username } = useParams();

  // Global states
  const { isUserIn, user } = useContext(LoginContext);

  // Local states
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sharedUser, setSharedUser] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const findProfileByUsername = async () => {
      setLoading(true);
      try {
        const res = await ProfileService.profileFindByUsername(username);
        setSharedUser(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    findProfileByUsername();
  }, [username]);

  const handleClick = () => {
    if (user?.profile?.id !== sharedUser?.profile?.id) {
      setModalIsOpen(true);
    } else {
      if (isUserIn) {
        setModalIsOpen(true);
      } else {
        navigate("/login");
        sessionStorage.setItem("shared-user", JSON.stringify(username));
      }
    }
  };

  return (
    <>
      <DashboardHeader />
      <main>
        <section className="sharedUserProfile">
          {loading && <Loading />}
          <div className="container">
            <div className="row">
              <SharedUserCard data={sharedUser} onClick={handleClick} />
              <AddSharedUserModal
                data={sharedUser}
                isOpen={modalIsOpen}
                setIsOpen={setModalIsOpen}
              />
              <SharedProfileModal data={user} />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default SharedUserProfile;
