import React from 'react';

const ChangePass = ({ setIsSelected }) => {
  return (
    <section className='changePassword'>
      <div className="contanier">
        <div className="row">
        <form>
          <div className="infoTitle">
        <div className="info">
        <h2>Change Password</h2>
        <p>Create a new password that is at least characters.</p>
     
        </div>
        <div className="btn">
        <button type="submit" className='btnSave'>Save</button>
        </div>
          </div>
        <div className='inpPass'>
          <label htmlFor="currentPassword">Type your current password*</label>
          <input type="password" id="currentPassword" name="currentPassword" placeholder='Current password'/>
        </div>
        <div className='inpPass'>
          <label htmlFor="newPassword">Type your new password*</label>
          <input type="password" id="newPassword" name="newPassword" placeholder='New password' />
        </div>
        <div className='inpPass'>
          <label htmlFor="confirmPassword">Retype your new password*</label>
          <input type="password" id="confirmPassword" name="confirmPassword" placeholder='Retype password' />
        </div>
        <button className='forget'>Forget Password</button>
        <button className='btnResponse'>Send</button>
        <button className='cancel' type="button" onClick={() => setIsSelected(false)}>Cancel</button>
      </form>
        </div>
      </div>
    
    </section>
  );
}

export default ChangePass;
