import * as DictionaryProvider from "../Provider/DictionaryProvider";

export const allSkills = async () => {
  const payload = {
    limit: 100,
    page: 1,
    title: "",
  };
  return await DictionaryProvider.getSkills(payload).then((res) => {
    return res.data.data.map((item) => ({
      value: item.id,
      label: item.title,
    }));
  });
};

export const allLanguages = async () => {
  const payload = {
    limit: 100,
    offset: 1,
    name: "",
  };
  return await DictionaryProvider.getLanguages(payload).then((res) => {
    return res.data.languages.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  });
};

export const allKeywords = async () => {
    const payload = {
      limit: 100,
      page: 1,
      title: "",
    };
    return await DictionaryProvider.getKeywords(payload).then((res) => {
      return res.data.keywords.map((item) => ({
        value: item,
        label: item,
      }));
    });
  };
