import {useTranslation} from "react-i18next";

const UpgradePackCaption = () => {
  // Translation
  const { t } = useTranslation();
  return (
    <div className="upgradePackCaption" >
      <h3 className="caption">{t("upgradePackCaption.caption")}</h3>
      <p className="text" >
        {t("upgradePackCaption.detail")}
      </p>
    </div>
  );
};

export default UpgradePackCaption;
