import React, { useContext } from "react";
import SettingSideBar from "./SettingSideBar";
import DashboardHeader from "../../../components/DashboardHeader";
import MobileNavBar from "../../../components/MobileNavBar";
import { MainContext } from "../../../utils/MainContext";

const General = ({ setIsSelected }) => {
  const { setSettingsAccountComponent } = useContext(MainContext);
  return (
    <div className="settingsComponent">
      <h2>General</h2>
      <button
        onClick={() => {
          setIsSelected(false);
          setSettingsAccountComponent("");
        }}
      >
        Save
      </button>
    </div>
  );
};

export default General;
