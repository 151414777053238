import {useTranslation} from "react-i18next";

const UpgradePackInfo = ({  list }) => {
  // Translation
  const { t,i18n } = useTranslation();
  let lng = i18n.language;
  return (
    <div className="upgradePackInfo" >
      <div className="leftSide">
        <ul className="infoList">
          {list.map((item) => (
            <li className="infoItem" key={item.id}>
              <h4 className="title">
                {lng === "az"
                  ? item.detailsAz
                  : lng === "en"
                  ? item.detailsEn
                  : lng === "ru"
                  ? item.detailsRu
                  : null}
              </h4>
              <p className="text">{t("upgradePackCaption.detail")}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="rightSide"></div>
    </div>
  );
};

export default UpgradePackInfo;
