import {useTranslation} from "react-i18next";

const UpgradePackNumber = () => {
    // Translation
    const { t } = useTranslation();
  return (
    <div className='upgradePackNumber' >
        <p className="number"
        >50.000+</p>
        <p className="text" >{t("upgradePackNumber.users")}</p>
    </div>
  )
}

export default UpgradePackNumber