import { useTranslation } from "react-i18next";
import { useState } from "react";
import ButtonsGroup from "../../../components/ButtonsGroup";
import UserIcon from "../../../assets/images/icons/userIcon.svg";
import UsersIcon from "../../../assets/images/icons/usersIcon.svg";
import CompanyIcon from "../../../assets/images/icons/companyIcon.svg";
import CheckBox from "../../../assets/images/checkboxIcon.svg"
import WharIsBucard from "../../../assets/images/whatIsBucard.png"
import BiznesBucard from "../../../assets/images/biznesBucard.png"
import CheckIcon from "../../../assets/images/icons/checkIcon.svg"
const ChangePlan = () => {
  // Translation
  const { t } = useTranslation();

  // Local state
  const [businessPlan, setBusinessPlan] = useState(false);

  return (
    <section className="changePlan" id="whatIsBucard">
      <div className="row">
        <div className="aboutBucard">
          <div className="ourUsers">
            <div className="icon">
              <img src={UserIcon} alt="user-icon" />
            </div>
            <div className="info">
              <p>120000+</p>
              <span>Ümumi istifadəçilərin sayı</span>
            </div>
          </div>
          <div className="ourUsers">
            <div className="icon">
              <img src={UsersIcon} alt="user-icon" />
            </div>
            <div className="info">
              <p>20000+</p>
              <span>Şəxsi profillərin sayı</span>
            </div>
          </div>
          <div className="ourUsers">
            <div className="icon">
              <img src={CompanyIcon} alt="user-icon" />
            </div>
            <div className="info">
              <p>1000+</p>
              <span>Şirkət hesablarının sayı</span>
            </div>
          </div>
        </div>
        <div className="whatIsBucard">
          <div className="whatIsInfo">
            <h4>Bucard nədir</h4>
            <p>
              Bucard fərdi şəxslərə və bizneslərə rəqəmsal həllər təklif edən
              biznes platformasıdır!
            </p>
            <p>
              Bucard-la siz NFC və QR kod vasitəsilə şəxsi, yaxud şirkət
              məlumatlarınızı digər şəxslərlə bölüşə və daha sürətlə əlaqə qura
              bilərsiniz.
            </p>
            <p className="infoIcon">
                <img src={CheckBox} alt="check-box" />
                Məkan və vəzifəyə görə axtarış
            </p>
            <p className="infoIcon">
                <img src={CheckBox} alt="check-box" />
                Məkan və vəzifəyə görə axtarış
            </p>
            <p className="infoIcon">
                <img src={CheckBox} alt="check-box" />
                Məkan və vəzifəyə görə axtarış
            </p>
          </div>
          <div className="whatIsImg">
            <img src={WharIsBucard} alt="" />
          </div>
        </div>
        <div className="bucardBiznes">
          <div className="biznesImg">
            <img src={BiznesBucard} alt="biznes-bucard" />
          </div>
          <div className="biznesInfo">
          <h4>Bucard nədir</h4>
            <p>
              Bucard fərdi şəxslərə və bizneslərə rəqəmsal həllər təklif edən
              biznes platformasıdır!
            </p>
            <p>
              Bucard-la siz NFC və QR kod vasitəsilə şəxsi, yaxud şirkət
              məlumatlarınızı digər şəxslərlə bölüşə və daha sürətlə əlaqə qura
              bilərsiniz.
            </p>
            <p className="infoIcon">
                <img src={CheckIcon} alt="check-box" />
                Məkan və vəzifəyə görə axtarış
            </p>
            <p className="infoIcon">
                <img src={CheckIcon} alt="check-box" />
                Məkan və vəzifəyə görə axtarış
            </p>
            <p className="infoIcon">
                <img src={CheckIcon} alt="check-box" />
                Məkan və vəzifəyə görə axtarış
            </p>
          </div>
        </div>
        {/* <div className="leftSide">
                    <h2 className="tabTitle">{t("changePlan.question")}</h2>
                    <ButtonsGroup state={{businessPlan, setBusinessPlan}}/>
                    <ul className={`tabList ${!businessPlan && "active"}`}>
                        <li className="tabItem">{t("changePlan.personalInfo1")}</li>
                        <li className="tabItem">{t("changePlan.personalInfo2")}</li>
                        <li className="tabItem">{t("changePlan.personalInfo3")}</li>
                        <li className="tabItem">{t("changePlan.personalInfo4")}</li>
                        <li className="tabItem">{t("changePlan.personalInfo5")}</li>
                    </ul>
                    <ul className={`tabList ${businessPlan && "active"}`}>
                        <li className="tabItem">{t("changePlan.businessInfo1")}</li>
                        <li className="tabItem">{t("changePlan.businessInfo2")}</li>
                        <li className="tabItem">{t("changePlan.businessInfo3")}</li>
                        <li className="tabItem">{t("changePlan.businessInfo4")}</li>
                        <li className="tabItem">{t("changePlan.businessInfo5")}</li>
                    </ul>
                </div>
                <div className={`rightSide ${businessPlan && "active"}`}></div> */}
      </div>
    </section>
  );
};

export default ChangePlan;
