import React, { useState } from "react";
import * as ProfileService from "../../Service/ProfileService";
import CustomInput from "../../../../components/CustomInput";
import OtpForm from "../../../../components/OtpForm";
import Spinner from "../../../../components/Spinner";

const ProfilePhoneNumbersEdit = ({
  profileData,
  closeModal,
  setProfileData,
}) => {
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileInfo, setProfileInfo] = useState({
    extra_phone_number: profileData?.extra_phone_numbers || [],
  });
  const [newPhoneNumber, setNewPhoneNumber] = useState({
    phone_number: "",
    isVerified: false,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (newPhoneNumber.isVerified) {
        await ProfileService.profileEdit(profileInfo);
        closeModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const verifyPhone = async () => {
    const body = {
      extra_phone_number: `+994${newPhoneNumber.phone_number}`,
    };
    try {
      if (body.extra_phone_number !== "") {
        await ProfileService.profileEdit({
          extra_phone_number: [
            { phone_number: `+994${newPhoneNumber.phone_number}` },
          ],
        });
        await ProfileService.sendOtpToPhone(body);
        setShowOtpModal(true);
        // closeModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const removePhone = async (phone) => {
    setLoading(true);
    try {
      const res = await ProfileService.deleteProfileDetail({
        field_name: "extra_phone_number",
        id: phone.id,
      });
      setProfileData((prev) => ({
        ...prev,
        extra_phone_numbers: res.data.extra_phone_numbers,
      }));
      setProfileInfo((prev) => ({
        ...prev,
        extra_phone_number: res.data.extra_phone_numbers,
      }));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return showOtpModal ? (
    <OtpForm
      onSubmit={(verified) => {
        if (verified) {
          closeModal();
          window.location.reload();
        }
      }}
      phone={`+994${newPhoneNumber.phone_number}`}
    />
  ) : (
    <div className="profilePhoneEdit">
      <div className="editPhoneArea">
        {profileInfo.extra_phone_number.map((phone) => (
          <button
            key={phone.id}
            className="phoneCaption"
            onClick={() => removePhone(phone)}
          >
            {phone.phone_number}
            <span>x</span>
          </button>
        ))}
      </div>
      <form className="infoPhoneForm">
        <CustomInput
          label={"Yeni nömrə"}
          placeholder="00 000 00 00"
          maxLength={9}
          errorMessage={errorMessage}
          value={newPhoneNumber.phone_number}
          onChange={(value) => {
            setNewPhoneNumber({
              phone_number: value?.trim(),
            });
            if (isNaN(value?.trim())) {
              setErrorMessage("Nömrə düzgün deyil");
            } else {
              setErrorMessage("");
            }
          }}
        />
      </form>
      <div className="btnGroup">
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          İmtina
        </button>
        <button
          className={`btn ${errorMessage && "id-disabled"}`}
          onClick={newPhoneNumber.isVerified ? onSubmit : verifyPhone}
          disabled={errorMessage !== ""}
        >
          Yadda saxla
        </button>
      </div>
    </div>
  );
};

export default ProfilePhoneNumbersEdit;
