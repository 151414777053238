import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as DashboardService from "../Modules/Dashboard/Service/DashboardService";
import { $api } from "../api/api";
import bIcon from "../assets/images/icons/b-icon.svg";
import closeModal from "../assets/images/icons/close-modal.svg";
import { MainContext } from "../utils/MainContext";
import Checkbox from "./Checkbox";
import Loading from "./Loading";

const AddProfileModal = (props) => {
  const { t } = useTranslation();
  const { boxes } = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [boxList, setBoxList] = useState([]);

  useEffect(() => {
    const allBoxModifiedList = async () => {
      setLoading(true);
      try {
        const res = await DashboardService.getAllBoxModifiedList(boxes);
        setBoxList(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    allBoxModifiedList();
  }, []);

  const sendData = async (body) => {
    try {
      const res = await DashboardService.moveBetweenBoxes(body);
      console.log(res);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const sendRequests = async (data) => {
    const promises = data.map(sendData);
    await Promise.all(promises);
  };

  const addNewProfiles = async (e) => {
    setLoading(true);
    try {
      if (selectedCards.length === 0) {
        throw new Error(t("addProfileModal.errorMsg2"));
      }
      if (selectedCards.length > 5) {
        throw new Error(t("addProfileModal.errorMsg1"));
      } else {
        const modifiedRequest = selectedCards.map((item) => {
          return {
            profile: item.profile,
            initial_box: item.boxId,
            target_box: props.boxId,
          };
        });
        await sendRequests(modifiedRequest);
      }
    } catch (error) {
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
      props.setProfileModalIsOpen(false);
    }
  };
  return (
    <div
      className={
        props.profileModalIsOpen ? "addProfileModal active" : "addProfileModal"
      }
    >
      {loading && <Loading />}
      <div
        className="modalOverlay"
        onClick={() => props.setProfileModalIsOpen(false)}
      ></div>
      <div className="modalBox">
        <div className="seperator"></div>
        <div className="addProfileHead">
          <h2 className="modalTitle">
            {t("addProfileModal.title")} <span className="limit">0 / 5</span>
          </h2>
          <div
            className="close"
            onClick={() => props.setProfileModalIsOpen(false)}
          >
            <img src={closeModal} alt="close-icon" />
          </div>
        </div>
        <ul className="usersList">
          {props.boxDetails.map((item) => (
            <li className="userCard" key={item.id}>
              <div className="userImg">
                <img
                  src={
                    item.avatar ? `${$api("image_url")}/${item.avatar}` : bIcon
                  }
                  alt={item.full_name}
                />
              </div>
              <p className="userName">{item.full_name}</p>
            </li>
          ))}
        </ul>
        <div className="generalUsers">
          {boxList
            .filter((boxItem) => boxItem.id !== props.boxId)
            .map((item) => (
              <div key={item.id}>
                {item.profiles.length !== 0 && (
                  <p className="boxName">
                    {item.icon} {item.title}
                  </p>
                )}
                <div className="usersInBox">
                  {item.profiles.map((profile) => (
                    <div
                      className={`addUserCard ${
                        selectedCards.some(
                          (card) => card.profile === profile.id
                        ) && "selected"
                      }`}
                      key={profile.id}
                    >
                      <div className="left">
                        <div className="profileImg">
                          <img
                            src={
                              profile.avatar
                                ? `${$api("image_url")}/${profile.avatar}`
                                : bIcon
                            }
                            alt={profile.full_name}
                          />
                        </div>
                        <div className="userInfo">
                          <p className="fullName">{profile.full_name}</p>
                          <Link
                            className="phone"
                            to={`tel:${profile.phone_number}`}
                          >
                            {profile.phone_number}
                          </Link>
                        </div>
                      </div>
                      <Checkbox
                        onChange={() => {
                          if (
                            selectedCards.some(
                              (card) => card.profile === profile.id
                            )
                          ) {
                            setSelectedCards(
                              selectedCards.filter(
                                (card) => card.profile !== profile.id
                              )
                            );
                          } else {
                            setSelectedCards([
                              ...selectedCards,
                              { profile: profile.id, boxId: item.id },
                            ]);
                          }
                        }}
                        checked={selectedCards.some(
                          (card) => card.profile === profile.id
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
        {errorMsg && <span className="errorMsg">{errorMsg}</span>}
        <button
          className="addBtn"
          onClick={async (e) => {
            e.preventDefault();
            await addNewProfiles(e);
          }}
        >
          {t("addProfileModal.add")}
        </button>
      </div>
      <div
        className="closeModal"
        onClick={() => props.setProfileModalIsOpen(false)}
      >
        <img src={closeModal} alt="close-icon" />
        <span>{t("addProfileModal.close")}</span>
      </div>
    </div>
  );
};

export default AddProfileModal;
