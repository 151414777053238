import React from "react";
import expImg from "../../../../assets/images/porfil-experience-test.svg";

const ProfileExperience = ({ profileData }) => {
  return (
    <div className="profileExperience">
      {profileData?.experiences?.map((item, index) => (
        <div className="expCard" key={index}>
          <div className="top">
            <div className="expImg">
              <img src={expImg} alt="experience_icon" />
            </div>
            <div className="info">
              <p className="title">{item?.title}</p>
              <div className="subTitle">
                <span>{item?.company}</span>
                <span className="dot">•</span>
                <span>{item?.employment_type}</span>
              </div>
            </div>
          </div>
          <div className="bottom">
            <p className="dates">
              {item?.start_date} -{" "}
              {item?.still_working_there ? "Present" : item?.end_date}
              {item?.start_date && item?.end_date && !item?.still_working_there && (
                <span>
                  {" "}
                  •{" "}
                  {Math.floor(
                    (new Date(item.end_date) - new Date(item.start_date)) /
                      (1000 * 60 * 60 * 24 * 365)
                  )}{" "}
                  years
                </span>
              )}
            </p>
            <p>{item?.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProfileExperience;
