const upgradePack = [
  {
    id: 1,
    packInfo: [
      { id: 1,
         detailsAz: "Kontaktları əldə etmək",
         detailsEn: "Get contacts",
         detailsRu: "Получить контакты",
     },
      { id: 2, 
        detailsAz: "Limitsiz qutu yaratmaq",
        detailsEn: "Create unlimited boxes",
        detailsRu: "Создавайте неограниченное количество ящиков",
       },
      { id: 3,
        detailsAz: "NFC / QR paylaşım" ,
        detailsEn: "NFC / QR sharing" ,
        detailsRu: "Обмен NFC/QRОбмен NFC/QR" ,
        },
    ],
  },
  {
    id: 2,
    packInfo: [
      { id: 1, 
        detailsAz: "Detallı profil məlumatları",
        detailsEn: "Detailed profile information",
        detailsRu: "Подробная информация профиля",
     },
      { id: 2,
        detailsAz: "Portfolio işləriLimitsiz qutu yaratmaq" ,
        detailsEn: "Portfolio works Unlimited box creation" ,
        detailsRu: "Работы в портфолио. Неограниченное создание коробок." ,
    },
      { id: 3,
         detailsAz: "Video təqdimat" ,
         detailsEn: "Video presentation" ,
         detailsRu: "Видео презентация" ,
    },
    ],
  },
];
export default upgradePack;
