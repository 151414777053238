import React, { useEffect, useState } from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";
import addCircle from "../../../../assets/images/icons/add-circle.svg";

const ProfileFeaturedEdit = ({
  profileData,
  setModalData,
  setProfileData,
  onAdd,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    title: profileData?.title,
    description: profileData?.description,
    url: profileData?.url,
  });

  const buttonDisabled =
    !profileInfo.title || !profileInfo.description || !profileInfo.url;

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        title: profileInfo.title,
        description: profileInfo.description,
        url: profileInfo.url,
      };
      const res = await ProfileService.userEdit(body);
      console.log("Response", res);
      setProfileData((prev) => ({
        ...prev,
        title: res?.data?.title,
        description: res?.data?.description,
        url: res?.data?.url,
      }));
      closeModal();
    } catch (error) {
      console.log("Error", error);
    }
  };

  const urlMetaData = async (url) => {
    try {
      const res = await ProfileService.urlMetaDatas({
        url,
      });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const isValidUrl = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.([a-z]{2,})|localhost)|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );
    return !!urlPattern.test(url);
  };

  useEffect(() => {
    if (profileInfo.url !== "" && isValidUrl(profileInfo.url)) {
      urlMetaData(profileInfo.url);
    }
  }, [profileInfo.url]);

  return (
    <div className="profileFeaturedEdit">
      <form className="infoEditForm" onSubmit={onSubmit}>
        <CustomInput
          label={"Title"}
          placeholder={"title"}
          onChange={(value) => {
            setProfileInfo((prev) => ({
              ...prev,
              title: value,
            }));
          }}
          value={profileInfo.title}
        />
        <CustomInput
          label={"Description"}
          placeholder={"description (max. 111 symbol)"}
          onChange={(value) => {
            setProfileInfo((prev) => ({
              ...prev,
              description: value,
            }));
          }}
          value={profileInfo.description}
        />
        <CustomInput
          label={"Paste or type a link to an article, file or account."}
          placeholder={"https://"}
          onChange={(value) => {
            setProfileInfo((prev) => ({
              ...prev,
              url: value,
            }));
          }}
          value={profileInfo.url}
        />
        <div className="btnGroup">
          <div className="addBtn">
            <button
              className="btnAdd"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onAdd();
              }}
            >
              <img src={addCircle} alt="Add new" />
              Əlavə et
            </button>
          </div>
          <button
            className="btn cancel"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            İmtina
          </button>
          <button
            className="btn"
            type="submit"
            disabled={buttonDisabled}
            onClick={onSubmit}
          >
            Yadda saxla
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileFeaturedEdit;
