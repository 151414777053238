import React, { useState } from "react";
import CustomInput from "../../../../components/CustomInput";
import Calendar from "../../../../assets/images/icons/calendar-2.svg";
import * as ProfileService from "../../Service/ProfileService";

const ProfileCreateExp = ({ closeModal, profileData, setProfileData }) => {
  const [profileInfo, setProfileInfo] = useState({
    title: profileData?.title,
    start_date: profileData?.start_date,
    end_date: profileData?.end_date,
    company: profileData?.company,
    description: profileData?.description,
    name: profileData?.name,
    employment_type: profileData?.employment_type,
    still_working_there: false,
  }); 

  const handleEmploymentTypeChange = (event) => {
    setProfileInfo((prev) => ({
      ...prev,
      employment_type: event.target.value,
    }));
  };
  const onSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const body = {
        title: profileInfo?.title,
        start_date: profileInfo?.start_date,
        end_date: profileInfo?.end_date,
        company: profileInfo?.company,
        description: profileInfo?.description,
        employment_type: profileInfo?.employment_type,
        still_working_there: profileInfo?.still_working_there,
      };
  
      const res = await ProfileService.profileEdit(body);
      setProfileData((prev)=>({
        ...prev,
        company:res?.data?.company,
        start_date:res?.data?.start_date,
        end_date:res?.data?.end_date,
        description:res?.data?.description,
        employment_type:res?.data?.employment_type,
      }))
      closeModal();
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };
  
  
  return (
    <div className="createProfile">
      <div className="experienceComp">
        <div className="experienceInp">
          <CustomInput
            placeholder={"Company Name"}
            label={"Company Name"}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                company: value,
              }));
            }}
            value={profileInfo.company}
          />
          <CustomInput
            placeholder={"Profession"}
            label={"Profession"}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                description: value,
              }));
            }}
            value={profileInfo.description}
          />
        </div>
        <div className="experienceInp2">
          <div className="selectInp">
            <label htmlFor="employment-type">Employment Type</label>
            <select
              name="employment-type"
              id="employment-type"
              value={profileInfo.employment_type}
              onChange={handleEmploymentTypeChange}
            >
              <option value="" disabled>Select employment type</option>
              <option value="full-time">Full-time</option>
              <option value="part-time">Part-time</option>
              <option value="internship">Internship</option>
              <option value="contract">Contract</option>
              <option value="freelance">Freelance</option>
              <option value="self_employed">Self-employed</option>
              <option value="volunteer">Volunteer</option>
            </select>
          </div>
          <div className="timeBox">
            <CustomInput
              label={"Start Date"}
              placeholder={"01.01.1990"}
              // rightIcon={Calendar}
              type={"date"}
              onChange={(value) => {
                setProfileInfo((prev) => ({
                  ...prev,
                  start_date: value,
                }));
              }}
              value={profileInfo.start_date}
            />
            <CustomInput
              label={"End Date"}
              placeholder={"01.01.1995"}
              // rightIcon={Calendar}
              type={"date"}
              onChange={(value) => {
                setProfileInfo((prev) => ({
                  ...prev,
                  end_date: value,
                }));
              }}
              value={profileInfo.end_date}
            />
          </div>
        </div>
        <div className="stillWorking">
          <input
            type="checkbox"
            checked={profileInfo.still_working_there}
            onChange={(e) =>
              setProfileInfo((prev) => ({
                ...prev,
                still_working_there: e.target.checked,
              }))
            }
          />
          <p>Still working there</p>
        </div>
      </div>
      <div className="btnGroup">
        <button
          className="cancel btn"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          Cancel
        </button>
        <button className="saveBtn btn" onClick={onSubmit}>
          Save Experience
        </button>
      </div>
    </div>
  );
};

export default ProfileCreateExp;
