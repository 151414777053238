import React, {useContext, useEffect, useState} from "react";

// Component
import UpgradePackNumber from "../components/UpgradePackNumber";
import UpgradePackCaption from "../components/UpgradePackCaption";
import UpgradePackInfo from "../components/UpgradePackInfo";
import PacketCard from "../components/PacketCard";
import upgradePack from "../db/upgradePack";
import Loading from "./Loading";
import * as HomeService from "../Modules/Home/Service/HomeService";
import {PROFILE_TYPES} from "../Modules/Profile/Service/ProfileService";
import {LoginContext} from "../utils/Auth";

const UpgeadeBuisnessPack = () => {
  const [planList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(LoginContext);

  useEffect(() => {
    const getPlans = async () => {
      setLoading(true);
      try {
        const res = await HomeService.planAllList();
        setPlanList(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getPlans();
  }, []);

  return (
    <div className="upgradeBuisnessPack">
      {loading && <Loading />}
      <div className="packetArea">
        <UpgradePackCaption />
        <div className="row">
          {planList.map((item) => {
            if (item.profile_type === PROFILE_TYPES.business) {
              return (
                <PacketCard
                  id={item.id}
                  data={item}
                  key={item.id}
                  profile_type={user?.profile?.profile_type}
                />
              );
            } else {
              return;
            }
          })}
        </div>
      </div>
      <UpgradePackCaption />
      <UpgradePackInfo list={upgradePack[0].packInfo} />
      <UpgradePackNumber />
      <UpgradePackInfo list={upgradePack[1].packInfo} />
    </div>
  );
};

export default UpgeadeBuisnessPack;
