import { $api } from "../../../api/api";
import $axios from "../../../api/axiosAccessor";

export const getProfileFindById = async (id) => {
  return await $axios.get($api("profile_detail_by_id", { id }));
};

export const getProfileFindByUsername = async (username) => {
  return await $axios.get($api("profile_detail_by_username", { username }));
};

export const getProfileSkills = async () => {
  return await $axios.get($api("skills"));
};

export const patchUserEdit = async (payload) => {
  return await $axios.patch($api("user_update_single"), payload);
};

export const putProfileUpdate = async (payload) => {
  return await $axios.put($api("user_update_single"), payload);
};

export const putProfileEdit = async (payload) => {
  return await $axios.put($api("profile_update"), payload);
};

export const deleteProfileDetailById = async (payload) => {
  return await $axios.delete(
    $api("profile_detail_delete_by_id", {
      field_name: payload.field_name,
      id: payload.id,
    })
  );
};

// CORE
export const getKeywordsAllList = async (payload) => {
  return await $axios.get(
    $api("keywords_all_list", {
      limit: payload.limit,
      page: payload.page,
      title: payload.title,
    })
  );
};
export const getSkillsAllList = async (payload) => {
  return await $axios.get(
    $api("skills_all_list", {
      limit: payload.limit,
      page: payload.page,
      title: payload.title,
    })
  );
};
export const getLanguagesAllList = async (payload) => {
  return await $axios.get(
    $api("languages_all_list", {
      name: payload.name,
      limit: payload.limit,
      offset: payload.offset,
    })
  );
};
export const getProfessionAllList = async (payload) => {
  return await $axios.get(
    $api("professions_all_list", {
      limit: payload.limit,
      page: payload.page,
      name: payload.name,
    })
  );
};

export const postSendOtpToPhone = async (payload) => {
  return await $axios.post($api("send_verify_extra_phone_number_otp"), payload);
};

export const postOtpConfirmation = async (payload) => {
  return await $axios.post(
    $api("confirm_verify_extra_phone_number_otp"),
    payload
  );
};

export const getSendOtpToEmail = async (payload) => {
  return await $axios.get($api("confirm_verify_extra_emails", { id: payload }));
};

export const postUrlMetaData = async (payload) => {
  return await $axios.post($api("get_url_meta_data"), payload);
};
