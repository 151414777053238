import React from "react";
import location from "../../../../assets/images/icons/location.svg";
import PenIcon from "../../../../assets/images/icons/edit-2.svg";

const ProfileInfo = ({
  profileData,
  onEditProfileInfo,
  showProfileInfoEdit,
  isOwner,
  isProUser,
}) => {
  return (
    <div className="profileInfo">
      <div className="title">
        <h2>{profileData?.full_name}</h2>
        <span className="boxCount">{profileData.in_boxes_count} qutuda</span>
      </div>
      <div className="description">
        <p className="username">{profileData?.username}</p>
        {isProUser && (
          <>
            <div className="location">
              <img src={location} alt="location" />
              <p>{profileData?.address}</p>
            </div>
            <div className="bio">
              <p>{profileData?.bio}</p>
              {showProfileInfoEdit && isOwner && (
                <div className="editIcon" onClick={() => onEditProfileInfo()}>
                  <img src={PenIcon} alt="phone_icon" />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileInfo;
