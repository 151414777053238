// src/components/ProfileInfoEdit.js
import React, { useState, useContext } from "react";
import CustomInput from "../../../../components/CustomInput";
import CustomTextArea from "../../../../components/CustomTextArea";
import ProfileMap from "./ProfileMap";
import { LoginContext } from "../../../../utils/Auth";
import * as ProfileService from "../../Service/ProfileService";
import locationImg from "../../../../assets/images/icons/location-add.svg";

const ProfileInfoEdit = ({ profileData, closeModal, setProfileData }) => {
  const [isLocation, setIsLocation] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    full_name: profileData?.full_name,
    username: profileData?.username,
    bio: profileData?.bio,
    address: profileData?.address,
  });
  const { setUser } = useContext(LoginContext);

  const buttonIsDisabled =
    !profileInfo.full_name ||
    !profileInfo.username ||
    !profileInfo.bio ||
    !profileInfo.address;

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        full_name: profileInfo?.full_name,
        username: profileInfo?.username,
        profile: {
          address: profileInfo?.address,
          bio: profileInfo?.bio,
        },
      };
      const res = await ProfileService.userEdit(body);
      setProfileData((prev) => ({
        ...prev,
        full_name: res?.data?.full_name,
        username: res?.data?.username,
        bio: res?.data?.profile?.bio,
        address: res.data?.profile?.address,
      }));
      // Reload page for new username
      window.location.pathname = `/profile/${res?.data?.username}`;
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddressSelect = (address) => {
    setProfileInfo((prev) => ({
      ...prev,
      address: address,
    }));
  };

  const handleLocationSelect = (location) => {
    setProfileInfo((prev) => ({
      ...prev,
      address: location.address, // Update address in profileInfo
    }));
    setIsLocation(false); // Hide the map after selecting location
  };

  return (
    <div className="profileInfoEdit">
      {isLocation ? (
        <ProfileMap 
          onLocationSelect={handleLocationSelect} 
          onAddressSelect={handleAddressSelect} 
        />
      ) : (
        <form className="infoEditForm">
          <CustomInput
            label={"Ad Soyad"}
            placeholder={"Ad Soyad"}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                full_name: value,
              }));
            }}
            value={profileInfo.full_name}
          />
          <CustomInput
            label={"İstifadəçi adı"}
            placeholder={"İstifadəçi adı daxil edin"}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                username: value,
              }));
            }}
            value={profileInfo.username}
          />
          <CustomInput
            label={"Çalışdığım müəssisə və vəzifə"}
            placeholder={"Daxil edin"}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                bio: value,
              }));
            }}
            value={profileInfo.bio}
          />
          <CustomInput
            label={"Ünvan"}
            placeholder={"Ünvan daxil edin"}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                address: value,
              }));
            }}
            value={profileInfo.address}
          />
          <div className="locationBtn" onClick={() => setIsLocation(true)}>
            <img src={locationImg} alt="location_add_icon" />
            <p>Xəritədən seç</p>
          </div>
          <div className="btnGroup">
            <button
              className="btn cancel"
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
            >
              İmtina
            </button>
            <button
              disabled={buttonIsDisabled}
              className="btn"
              onClick={onSubmit}
            >
              Yadda saxla
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ProfileInfoEdit;
