import React, { useEffect, useState } from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";
import infoIcon from "../../../../assets/images/icons/info-circle-gray.svg";
import addCircle from "../../../../assets/images/icons/add-circle.svg";
import plusIcon from "../../../../assets/images/icons/add-circle-gray.svg";
import Spinner from "../../../../components/Spinner";
import * as DictionaryService from "../../../Dictionaries/Service/DictionaryService";
import Select from "react-select";
import { toastMsg } from "../../../../utils/swal";

const ProfileSkillsEdit = ({ profileData, closeModal, setProfileData }) => {
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    skills: profileData?.skills || [],
  });
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const getSkillsList = async () => {
      setLoading(true);
      try {
        const res = await DictionaryService.allSkills();
        setSkills(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getSkillsList();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit(profileInfo);
      setProfileData(res);
      setProfileInfo((prev) => ({
        ...prev,
        skills: res,
      }));
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const addNewInp = () => {
  //   setProfileInfo((prev) => ({
  //     ...prev,
  //     skills: [
  //       ...prev.skills,
  //       {
  //         id: uuidv4(),
  //         title: "",
  //       },
  //     ],
  //   }));
  // };

  const removeInp = async (input) => {
    setLoading(true);
    try {
      if (input.id && profileData.skills.length === profileInfo.skills.length) {
        const res = await ProfileService.deleteProfileDetail({
          field_name: "skill",
          id: input.id,
        });
        setProfileData((prev) => ({
          ...prev,
          skills: res?.data?.skills,
        }));
        setProfileInfo((prev) => ({
          ...prev,
          skills: res?.data?.skills,
        }));
      } else {
        setProfileInfo((prev) => ({
          ...prev,
          skills: prev.skills.filter((item) => item !== input),
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const handleInputChange = (index, value) => {
  //   const updatedSkills = profileInfo.skills.map((skill, i) => {
  //     if (i === index) {
  //       return { ...skill, title: value };
  //     }
  //     return skill;
  //   });
  //   setProfileInfo((prev) => ({
  //     ...prev,
  //     skills: updatedSkills,
  //   }));
  // };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileSkillsEdit">
      <div className="infoSkillsEdit">
        <div className="editSkillsArea">
          {profileInfo.skills.map((skill) => (
            <button
              key={skill.id}
              className="skillsCaption"
              onClick={() => removeInp(skill)}
            >
              {skill.title}
              <span>x</span>
            </button>
          ))}
        </div>
        <div className="addSkillsArea">
          <Select
            placeholder={"Seçin..."}
            closeMenuOnSelect={true}
            escapeClearsValue={true}
            options={skills}
            onChange={(selected) => {
              const existing = profileInfo.skills.find(
                (item) => item.id === selected.value
              );
              if (existing) {
                toastMsg(
                  "Bu bacarıq artıq əlavə olunub !",
                  "#ffffff",
                  "#000000"
                );
              } else {
                setProfileInfo((prev) => ({
                  ...prev,
                  skills: [
                    ...prev.skills,
                    {
                      id: selected.value,
                      title: selected.label,
                    },
                  ],
                }));
              }
            }}
            isDisabled={profileInfo.skills.length >= 20}
          />
          {/* {profileInfo.skills.map((skill, index) => (
            <div key={skill.id} className="socialInp">
              <CustomInput
                placeholder={"Type"}
                rightIcon={plusIcon}
                onChange={(value) => handleInputChange(index, value)}
                value={skill.title}
              />
              <span onClick={() => removeInp(skill)}>x</span>
            </div>
          ))} */}
          <div className="infoSkills">
            <img src={infoIcon} alt="Info Icon" />
            Sadəcə 20 bacarıq əlavə edə bilərsiniz.
          </div>
        </div>
        <div className="btnGroup">
          {/* <div className="addBtn">
            <button className="btnAdd" type="button" onClick={addNewInp}>
              <img src={addCircle} alt="Add Circle" />
              Add new
            </button>
          </div> */}
          <button
            className="btn cancel"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            İmtina
          </button>
          <button className="btn" onClick={onSubmit}>
            Yadda saxla
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSkillsEdit;
