// Router
import {Link} from "react-router-dom";

// Images
import logo from "../assets/images/logo.svg";

// Icons
import fbIcon from "../assets/images/icons/fbIcon.svg";
import inIcon from "../assets/images/icons/inIcon.svg";
import instagramIcon from "../assets/images/icons/instagram-icon.svg";
import wpIcon from "../assets/images/icons/whatsapp-icon.svg";

// Translation
import {useTranslation} from "react-i18next";

const Footer = () => {
  // Translation
  const { t } = useTranslation();

  return (
    <footer className="footer" id="contacts">
      <div className="container">
        <div className="row">
          <div className="siteInfo">
            <div className="footerLogo">
              <Link to="/">
                <img src={logo} alt="bucard-logo" />
              </Link>
            </div>
            <p>
              © {t("footer.copyrights")} • {new Date().getFullYear()} Bucard.az
            </p>
          </div>
          <div className="contactInfo">
            <Link to="tel:+994557005200">+994 55 700 52 00</Link>
            <Link to="mailto:contact@bucard.az">contact@bucard.az</Link>
          </div>
          <ul className="socials">
            <li className="socialItem">
              <Link to="https://www.facebook.com/bucard.az" target="_blank">
                <img src={fbIcon} alt="facebook-icon" />
              </Link>
            </li>
            <li className="socialItem">
              <Link
                to="https://www.linkedin.com/company/bucard/"
                target="_blank"
              >
                <img src={inIcon} alt="linkedin-icon" />
              </Link>
            </li>
            <li className="socialItem">
              <Link to="https://wa.me/+994557005200" target="_blank">
                <img src={wpIcon} alt="whatsapp-icon" />
              </Link>
            </li>
            <li className="socialItem">
              <Link to="https://www.instagram.com/bucard.az/" target="_blank">
                <img src={instagramIcon} alt="instagram-icon" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
