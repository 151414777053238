import React, { useState } from "react";
import * as ProfileService from "../../Service/ProfileService";
import CustomInput from "../../../../components/CustomInput";
import Spinner from "../../../../components/Spinner";
import OtpForm from "../../../../components/OtpForm";

const ProfileEmailEdit = ({ profileData, closeModal, setProfileData }) => {
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileInfo, setProfileInfo] = useState({
    extra_email: profileData?.extra_emails || [],
  });
  const [newEmail, setNewEmail] = useState({
    email: "",
    isVerified: false,
  });

  const onSubmit = async (e) => {
    e.preventDefault()
    const body = {
      extra_email: newEmail.email,
    };
    try {
      if (body.extra_email !== "") {
        const res = await ProfileService.profileEdit({
          extra_email: [{ email: newEmail.email }],
        });
        // const newEmailId = res?.extra_emails?.find(
        //   (item) => item.email === newEmail.email
        // )?.id;
        // await ProfileService.sendOtpToEmail(newEmailId);
        setProfileInfo((prev) => ({
          ...prev,
          extra_email: res.extra_emails,
        }));
        setProfileData((prev) => ({
          ...prev,
          extra_email: res.extra_emails,
        }));
        // setShowOtpModal(true);
      }
      window.location.reload()
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      closeModal()
    }
  };

  const removeEmail = async (email) => {
    setLoading(true);
    try {
      const res = await ProfileService.deleteProfileDetail({
        field_name: "extra_email",
        id: email.id,
      });
      setProfileData((prev) => ({
        ...prev,
        extra_email: res.data.extra_emails,
      }));
      setProfileInfo((prev) => ({
        ...prev,
        extra_email: res.data.extra_emails,
      }));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return showOtpModal ? (
    <OtpForm
      onSubmit={(verified) => {
        if (verified) {
          closeModal();
          window.location.reload();
        }
      }}
      email={newEmail.email}
    />
  ) : (
    <div className="profilePhoneEdit">
      <div className="editPhoneArea">
        {profileInfo.extra_email.map((email) => (
          <button
            key={email.id}
            className="phoneCaption"
            onClick={() => removeEmail(email)}
          >
            {email.email}
            <span>x</span>
          </button>
        ))}
      </div>
      <form className="infoPhoneForm">
        <CustomInput
          label={"Yeni elektron poçt"}
          placeholder="info@bucard.az"
          errorMessage={errorMessage}
          value={newEmail.email}
          onChange={(value) => {
            setNewEmail({
              email: value?.trim(),
            });
            if (value?.trim() === "") {
              setErrorMessage("Elektron poçt daxil edin");
            } else if (!value.includes("@")) {
              setErrorMessage("Elektron poçt düzgün deyil");
            } else {
              setErrorMessage("");
            }
          }}
        />
      </form>
      <div className="btnGroup">
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          İmtina
        </button>
        <button
          className={`btn ${errorMessage && "id-disabled"}`}
          onClick={onSubmit}
          disabled={errorMessage !== ""}
        >
          Yadda saxla
        </button>
      </div>
    </div>
  );
};

export default ProfileEmailEdit;
