import React from "react";
import EditExperience from "../../../../assets/images/icons/editExperience.svg";
import ExampleCompany from "../../../../assets/images/icons/exampleCompany.svg";
import addCircle from "../../../../assets/images/icons/add-circle.svg";

const ProfileEditExperience = ({onAdd,closeModal}) => {
  return (
    <div className="editExperience">
      {/* <div className="experienceTitle">
        <p className="experienceInfo">Experience</p>
        <button className="editExperienceBtn">
          <img src={EditExperience} alt="" />
        </button>
      </div> */}

      <div className="profileExperience">
        <div className="expCard">
          <div className="top">
            <div className="expImg">
              <img src={ExampleCompany} alt="experience_icon" />
            </div>
            <div className="info">
              <div className="editPosition">
                <p className="title">Position</p>
                <button className="btnAdd" type="button" onClick={(e)=>{
            e.preventDefault()
            onAdd();
          }}>edit</button>
              </div>
              <div className="subTitle">
                <span>Company</span>
                <span className="dot">•</span>
                <span>Full Time</span>
              </div>
            </div>
          </div>
          <div className="bottom">
            <p className="dates">
              Jun 2020 - Aug 2023 - <span>3 years</span>
            </p>
            <p>
              I'm a UX/UI Designer with 5+ years of experience designing
              engaging and user-friendly.
            </p>
          </div>
        </div>
      </div>

      <div className="btnGroup">
        <div className="addBtn">
          <button className="btnAdd" type="button" onClick={(e)=>{
            e.preventDefault()
            onAdd();
          }}>
            <img src={addCircle} alt="" />
            Add new
          </button>
        </div>
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal()
          }}
        >
          Cancel
        </button>
        <button className="btn">Save</button>
      </div>
    </div>
  );
};

export default ProfileEditExperience;
