// Image
import profilImgFree from "../assets/images/profil-img-test.svg";
import profilImgBuisnes from "../assets/images/profil-img-test2.svg";
import profilDescription from "../assets/images/prf-description-test.svg";
import buisnesDescription from "../assets/images/prf-description-test2.svg";
import dots from "../assets/images/icons/dots.svg";

import bgDetail from "../assets/images/upgrade-pack-bg-dtail.png";
import { useTranslation } from "react-i18next";

const UpgradePackPacketArea = () => {
  // Translation
  const { t } = useTranslation();
  return (
    <div className="upgradePacketArea">
      <div className="packetCard">
        <div className="profilArea">
          <div className="profil">
            <div className="top">
              <div className="profilImg">
                <img src={profilImgFree} alt="profil-img" />
              </div>
              <div className="destriptionImg">
                <img src={profilDescription} alt="destription-img" />
              </div>
            </div>
            <div className="bottom">
              <div className="about">
                <h3 className="profilName">Elon Musk</h3>
              </div>
              <h6 className="tel">+994 50 9876543</h6>
              <p className="addition"> 1286 {t("upgradePackPacketArea.save")}</p>
            </div>
            <img src={dots} alt="dots" className="threeDot" />
          </div>
          <h4 className="profilTypeName">{t("upgradePackPacketArea.personalProfil")}</h4>
        </div>
        <div className="overlayBg">
          <img src={bgDetail} alt="description" />
        </div>
      </div>
      <div className="packetCard">
        <div className="profilArea">
          <div className="profil">
            <div className="top">
              <div className="profilImg">
                <img src={profilImgBuisnes} alt="profil-img" />
              </div>
              <div className="destriptionImg">
                <img src={buisnesDescription} alt="destription-img" />
              </div>
            </div>
            <div className="bottom">
              <div className="about">
                <h3 className="profilName">Google</h3>
                <p className="addition">
                  {" "}
                  <span>1286</span> {t("upgradePackPacketArea.save")}
                </p>
              </div>
              <p className="addition"> {t("upgradePackPacketArea.addition1")}</p>
              <p className="addition">{t("upgradePackPacketArea.addition2")}</p>
            </div>
            <img src={dots} alt="dots" className="threeDot" />
          </div>
          <h4 className="profilTypeName">{t("upgradePackPacketArea.businessProfil")}</h4>
        </div>
      </div>
    </div>
  );
};

export default UpgradePackPacketArea;
