// src/components/ProfileMap.js
import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 40.1431, // Azerbaijan'ın merkez koordinatları
  lng: 47.5769,
};

const ProfileMap = ({ onLocationSelect, onAddressSelect }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [address, setAddress] = useState("");

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    if (loadError) {
      console.error("Google Maps API loading error:", loadError);
    }
  }, [loadError]);

  const handleMapClick = async (event) => {
    const latLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setSelectedLocation(latLng);
    
    // Reverse Geocoding
    try {
      const geocoder = new window.google.maps.Geocoder();
      const results = await new Promise((resolve, reject) => {
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === "OK" && results[0]) {
            resolve(results[0].formatted_address);
          } else {
            reject(status);
          }
        });
      });
      setAddress(results);
      if (onAddressSelect) {
        onAddressSelect(results); // Pass address to parent component
      }
      if (onLocationSelect) {
        onLocationSelect({ ...latLng, address: results }); // Optionally pass location
      }
    } catch (error) {
      console.error("Error retrieving address:", error);
    }
  };

  const handleSave = () => {
    if (selectedLocation && onLocationSelect) {
      onLocationSelect({ ...selectedLocation, address });
    } else {
      console.warn("No location selected or onLocationSelect is not provided");
    }
  };

  return isLoaded ? (
    <div>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onClick={handleMapClick}
        >
          {selectedLocation && <Marker position={selectedLocation} />}
        </GoogleMap>
      </LoadScript>
      {selectedLocation && (
        <div className="map-controls">
          <p>Selected Address: {address}</p>
          <button onClick={handleSave}>Save Location</button>
        </div>
      )}
    </div>
  ) : <div>Loading...</div>;
};

export default ProfileMap;
