// Router
import { Link, useLocation, useNavigate } from "react-router-dom";

import { $api } from "../api/api";

// Images
import bIcon from "../assets/images/icons/b-icon.svg";
import staticBanner from "../assets/images/static-banner.svg";

// Translation
import { useTranslation } from "react-i18next";
import { PROFILE_TYPES } from "../Modules/Profile/Service/ProfileService";
import { useContext } from "react";
import { LoginContext } from "../utils/Auth";

const ProfileCard = ({ data, inBox, remove, add, in_boxes_by_owner }) => {
  const path = useLocation().pathname;
  // Translation
  const { t } = useTranslation();

  const { user } = useContext(LoginContext);
  const navigate = useNavigate();

  // Format statistic numbers
  const formatNumber = (number) => {
    const units = [
      t("numberFormats.k"),
      t("numberFormats.m"),
      t("numberFormats.b"),
      t("numberFormats.t"),
    ];
    const delimiter = ",";
    if (isNaN(number)) {
      return "";
    }
    if (number < 1000) {
      return number.toString();
    }
    const absNumber = Math.abs(number);
    let unitIndex = Math.floor(Math.log10(absNumber) / 3);
    let formattedNumber = (number / Math.pow(1000, unitIndex)).toFixed(1);
    if (formattedNumber.endsWith(".0")) {
      formattedNumber = formattedNumber.slice(0, -2);
    }
    if (unitIndex < units.length) {
      formattedNumber += " " + units[unitIndex - 1];
    } else {
      formattedNumber += " " + units[units.length - 1];
    }
    return formattedNumber.replace(".", delimiter);
  };

  return (
    <div className="profileCard">
      <Link to={`/profile/${data?.username}`} className="cardTop">
        <div
          className={`profileImg ${
            data.profile_type === PROFILE_TYPES.business && "goldBrd"
          } ${!data.avatar && "bIcon"}`}
        >
          {data.avatar ? (
            <img
              src={
                path.includes("/search-result")
                  ? data.avatar
                  : `${
                      data?.profile?.avatar.includes("api.")
                        ? ""
                        : $api("image_url") + "/"
                    }${data.avatar}`
              }
              alt={data.fullName}
            />
          ) : (
            <img src={bIcon} alt="b-icon" />
          )}
        </div>
        <div className="profileBanner">
          <img
            src={
              data?.background_image
                ? path.includes("/search-result")
                  ? data.background_image
                  : `${
                      data?.profile?.background_image.includes("api.")
                        ? ""
                        : $api("image_url") + "/"
                    }${data.background_image}`
                : staticBanner
            }
            alt={data?.profession?.name}
          />
        </div>
      </Link>
      <div className="cardBottom">
        <div className="cardTitle">
          <div className="title">
            <p className="fullName">{data.full_name}</p>
            {data.profile_type === PROFILE_TYPES.business && (
              <span className="badge">{t("profileCard.company")}</span>
            )}
          </div>
          {data.profile_type !== PROFILE_TYPES.free && (
            <span className="companyCount">
              {formatNumber(data?.in_boxes_count)} {t("profileCard.saved")}
            </span>
          )}
        </div>
        <div className="cardInfo">
          <div className="info">
            <Link className="phone" to={`tel:${data.phone_number}`}>
              {data.phone_number}
            </Link>
            <p className="profession">{data?.profile?.profession?.name}</p>
            <p className="location">{data?.profile?.address}</p>
            {data?.profile_type === PROFILE_TYPES.free && (
              <p className="savedCount">
                {data.in_boxes_count} {t("profileCard.saved")}
              </p>
            )}
          </div>
          {inBox && (
            <button className="cardBtn" onClick={remove}>
              - {t("profileCard.remove")}
            </button>
          )}
          {!inBox && data?.id !== user?.profile?.id && (
            <button className="cardBtn" onClick={add}>
              + {t("profileCard.add")}
            </button>
          )}
          {!inBox && data?.id === user?.profile?.id && (
            <button
              className="cardBtn"
              onClick={() => {
                navigate(`/profile/${user?.username}`);
              }}
            >
              {t("profileCard.view")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
