import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import backArrow from "../../../assets/images/icons/back-arrow.svg";
import LanguageSelector from "../../../components/LanguageSelector";
import MobileLanguageSelect from "../../../components/MobileLanguageSelect";
import { MainContext } from "../../../utils/MainContext";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import SuccessLabel from "../../../components/SuccessLabel";

const Register = () => {
  // Translation
  const { t } = useTranslation();

  // Global states
  const {
    step,
    setStep,
    setPhone,
    mobileLangVisivle,
    registerSuccessMsg,
    setRegisterSuccessMsg,
  } = useContext(MainContext);

  //Local States
  const [isActivated, setIsActivated] = useState(false);

  // Router
  const navigate = useNavigate();

  // Reset input
  useEffect(() => {
    setPhone("");
  }, [setPhone, navigate]);

  setTimeout(() => {
    setRegisterSuccessMsg("");
  }, 5000);

  return (
    <main>
      <section className="register">
        <div className="screen">
          <div className="leftSide">
            <div className="formHeader">
              <div
                className="navigate"
                onClick={() => {
                  navigate("/");
                  setStep(1);
                  setPhone("");
                }}
              >
                <img src={backArrow} alt="go-back" />
                <p className="navigateInfo">{t("register.goBack")}</p>
              </div>
              <LanguageSelector />
            </div>
            <div className="formSteps">
              {registerSuccessMsg && <SuccessLabel text={registerSuccessMsg} />}
              {step === 1 ? (
                <StepOne setIsActivated={setIsActivated} />
              ) : step === 2 ? (
                <StepTwo
                  isActivated={isActivated}
                  setIsActivated={setIsActivated}
                />
              ) : (
                <StepThree />
              )}
            </div>
          </div>
          <div className={`rightSide step${step}`}></div>
        </div>
        {mobileLangVisivle && <MobileLanguageSelect />}
      </section>
    </main>
  );
};
export default Register;
