import messenger from "../assets/images/icons/messenger-icon.svg";
import whatsapp from "../assets/images/icons/whatsapp-icon.svg";
import linkedin from "../assets/images/icons/linkedin-icon.svg";
import instagram from "../assets/images/icons/instagram-icon.svg";
import telegram from "../assets/images/icons/telegram-icon.svg";

const socialBtns = [
  {
    id: 1,
    title: "facebook-messenger",
    url: "https://www.messenger.com/t/?text=",
    icon: messenger,
  },
  {
    id: 2,
    title: "whatsapp",
    url: "https://wa.me/?text=",
    icon: whatsapp,
  },
  {
    id: 3,
    title: "linkedin",
    url: "https://www.linkedin.com",
    icon: linkedin,
  },
  {
    id: 4,
    title: "instagram",
    url: "https://www.instagram.com/direct/t/?text=",
    icon: instagram,
  },
  {
    id: 5,
    title: "telegram",
    url: "https://t.me/?text=",
    icon: telegram,
  },
];

export default socialBtns;
