import React, { useState } from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";
import infoIcon from "../../../../assets/images/icons/info-circle-gray.svg";

const ProfileVideoEdit = ({ profileData, closeModal, setProfileData }) => {
  const [videoUrl, setVideoUrl] = useState(
    profileData.intro_videos[0]?.intro_video_url
  );
  const buttonIsDisabled = videoUrl?.trim().length === 0;

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await ProfileService.profileEdit({
        intro_videos: [
          {
            id: profileData.intro_videos[0]?.id,
            intro_video_url: videoUrl,
          },
        ],
      });
      setProfileData((prev) => ({
        ...prev,
        intro_videos: res.intro_videos,
      }));
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="profileInfoEdit">
      <form className="infoEditForm">
        <CustomInput
          label={"Video linki"}
          placeholder={"Linki daxil edin"}
          value={videoUrl}
          onChange={(value) => {
            setVideoUrl(value);
          }}
        />
        <div className="infoSkills">
          <img src={infoIcon} alt="" />
          Siz yalnız "Youtube" linki daxil etməlisiniz.
        </div>
        <div className="btnGroup">
          <button
            className="btn cancel"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            İmtina
          </button>
          <button
            disabled={buttonIsDisabled}
            className="btn"
            onClick={onSubmit}
          >
            Yadda saxla
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileVideoEdit;
