import React, { useContext, useState } from 'react';
import ArrowRight from "../../../assets/images/icons/arrow-right.svg";
import { MainContext } from '../../../utils/MainContext';
import ChangePass from './ChangePass';
import ProfileStatus from './ProfileStatus';

const Security = () => {
    const { settingsServiceComponent, setSettingsServiceComponent } = useContext(MainContext);
    const [isSelected, setIsSelected] = useState(false);

    return (
        <section className="security">
            <div className="container">
                <div className="row">
                    {!isSelected ? (
                        <>
                            <h2>Security</h2>
                            <p>View or update your account details</p>
                            <div className="securityComponent">
                                <div className="changePass" onClick={() => {
                                    setSettingsServiceComponent('password');
                                    setIsSelected(true);
                                }}>
                                    <span>Change your password</span>
                                    <img src={ArrowRight} alt="Arrow Right" />
                                </div>
                                <div className="status" onClick={() => {
                                    setSettingsServiceComponent('status');
                                    setIsSelected(true);
                                }}>
                                    <span>Profile Status</span>
                                    <img src={ArrowRight} alt="Arrow Right" />
                                </div>
                            </div>
                        </>
                    ) : (
                        settingsServiceComponent === 'password' ? (
                            <ChangePass setIsSelected={setIsSelected} />
                        ) : settingsServiceComponent === 'status' ? (
                            <ProfileStatus setIsSelected={setIsSelected} />
                        ) : null
                    )}
                </div>
            </div>
        </section>
    );
}

export default Security;

