import React, { useState, useContext } from "react";
import { settingsPages } from "../../../constants/constants";
import { MainContext } from "../../../utils/MainContext";

const SettingSideBar = () => {
  const { settingsComponent, setSettingsComponent } = useContext(MainContext);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const handleBurgerMenuToggle = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  return (
    <div className="settingsItem">
      <div className="burgerMenu" onClick={handleBurgerMenuToggle}>
        ☰
      </div>
      <ul className={`settingsBar ${isBurgerMenuOpen ? 'open' : ''}`}>
        <li className="items" onClick={() => setSettingsComponent(settingsPages.plan)}>
          Plan
        </li>
        {/* <li className="items" onClick={() => setSettingsComponent(settingsPages.payments)}>
          Payments
        </li> */}
        <li className="items" onClick={() => setSettingsComponent(settingsPages.language)}>
          Language
        </li>
        <li className="items" onClick={() => setSettingsComponent(settingsPages.security)}>
          Security
        </li>
        <li className="items" onClick={() => setSettingsComponent(settingsPages.deactive)}>
          Deactive or delete account
        </li>
        <li className="items" onClick={() => setSettingsComponent(settingsPages.privacy)}>
          Privacy Policy
        </li>
        <li className="items" onClick={() => setSettingsComponent(settingsPages.data)}>
          Data Privacy
        </li>
        <li className="items" onClick={() => setSettingsComponent(settingsPages.help)}>
          Help
        </li>
        <li className="items" onClick={() => setSettingsComponent(settingsPages.about)}>
          About us
        </li>
      </ul>
    </div>
  );
};

export default SettingSideBar;
