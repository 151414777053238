import { useState } from "react";

const useIsDevMode = () => {
  const mode = process.env.NODE_ENV;
  const [isDevMode, setIsDevMode] = useState(false);

  if (mode === "development") {
    setIsDevMode(true);
  } else {
    setIsDevMode(false);
  }

  return isDevMode;
};

export default useIsDevMode;
