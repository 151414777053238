import {useContext} from "react";

// Router
import {Link} from "react-router-dom";

// Images
import boxUsers from "../assets/images/icons/box-users.svg";
import menuDots from "../assets/images/icons/menu-dots.svg";

// Context
import {MainContext} from "../utils/MainContext";

// Swal
import {confirmSwal} from "../utils/swal";

// Translation
import {useTranslation} from "react-i18next";

import * as DashboardService from "../Modules/Dashboard/Service/DashboardService";

const MobileBox = ({ data, openBoxMenu, openBoxId, setOpenBoxId }) => {
  // Translation
  const { t } = useTranslation();

  // Global states
  const { setEditBoxOpen, setSelectedBox, setEditBoxId, boxes, setBoxes } =
    useContext(MainContext);

  // Delete selected box
  const deleteBox = (boxId) => {
    confirmSwal(
      t("allBoxes.deleteQuestion"),
      t("allBoxes.deleteInfo"),
      t("allBoxes.grant"),
      t("allBoxes.deny"),
      setOpenBoxId,
      async () => {
        setOpenBoxId(null);
        try {
          await DashboardService.deleteBox(boxId);
          const updated = boxes.filter((item) => item.id !== boxId);
          setBoxes(updated);
        } catch (err) {
          console.log(err);
        }
      },
      t("confirmModal.grant"),
      t("confirmModal.deny")
    );
  };

  return (
    <Link
      className="mobileBox"
      to={`/box-details/${data.id}`}
      onClick={(e) => {
        setSelectedBox(data);
      }}
    >
      <div className="boxLeft">
        <div className="boxIcon">
          <p className="emoji">{data.icon}</p>
        </div>
        <div className="boxInfo">
          <p className="boxName">{data.title}</p>
          <div className="boxCount">
            <div className="boxUsers">
              <img src={boxUsers} alt="box-users-icon" />
            </div>
            <span className="usersCount">{data.profiles_count}</span>
          </div>
        </div>
      </div>
      <div className="boxRight">
        <div
          className="boxMenu"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            openBoxMenu(data.id);
          }}
        >
          <img src={menuDots} alt="menu-dots" />
        </div>
      </div>
      <div
        className={openBoxId === data.id ? "boxOptions isOpen" : "boxOptions"}
        id={data.id}
      >
        <div className="seperator"></div>
        <div className="boxDetail">
          <p>{openBoxId === data.id && data.title}</p>
          <div className="boxIcon">
            <p className="emoji">{openBoxId === data.id && data.emoji}</p>
          </div>
        </div>
        <div
          className="option"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setEditBoxOpen(true);
            setEditBoxId(data.id);
            setOpenBoxId(null);
          }}
        >
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4997 22.0003H4.07973C2.91973 22.0003 1.96973 21.0703 1.96973 19.9303V5.09035C1.96973 2.47035 3.91973 1.28035 6.30973 2.45035L10.7497 4.63035C11.7097 5.10035 12.4997 6.35035 12.4997 7.41035V22.0003Z"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.97 15.0599V18.8399C21.97 20.9999 20.97 21.9999 18.81 21.9999H12.5V10.4199L12.97 10.5199L17.47 11.5299L19.5 11.9799C20.82 12.2699 21.9 12.9499 21.96 14.8699C21.97 14.9299 21.97 14.9899 21.97 15.0599Z"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.5 9H8.97"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.5 13H8.97"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.4697 11.5305V14.7505C17.4697 15.9905 16.4597 17.0005 15.2197 17.0005C13.9797 17.0005 12.9697 15.9905 12.9697 14.7505V10.5205L17.4697 11.5305Z"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.9597 14.8703C21.8997 16.0503 20.9197 17.0003 19.7197 17.0003C18.4797 17.0003 17.4697 15.9903 17.4697 14.7503V11.5303L19.4997 11.9803C20.8197 12.2703 21.8997 12.9503 21.9597 14.8703Z"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <p className="optionInfo">{t("allBoxes.edit")}</p>
        </div>
        <div
          className="option"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            deleteBox(data.id);
          }}
        >
          <div className="icon">
            <svg
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 6H17V19C17 19.5304 16.7891 20.0391 16.4141 20.4142C16.039 20.7893 15.5304 21 15 21H5C4.46957 21 3.96077 20.7893 3.58569 20.4142C3.21062 20.0391 3 19.5304 3 19V6Z"
                stroke="#44475C"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                d="M0 6H3.6499C4.0476 5.99919 4.43609 5.87988 4.76562 5.65723C5.09516 5.43457 5.35086 5.11868 5.5 4.75L6.5 2.26001C6.64757 1.88946 6.90252 1.57145 7.23218 1.34692C7.56184 1.1224 7.951 1.00161 8.34985 1H11.6499C12.0488 1.00161 12.4379 1.1224 12.7676 1.34692C13.0972 1.57145 13.3524 1.88946 13.5 2.26001L14.5 4.75C14.6498 5.12026 14.9068 5.43725 15.2383 5.66003C15.5698 5.88282 15.9605 6.00119 16.3599 6H20"
                stroke="#44475C"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                d="M13.4999 10L6.49988 17"
                stroke="#44475C"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                d="M13.4999 17L6.49988 10"
                stroke="#44475C"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
          <p className="optionInfo">{t("allBoxes.delete")}</p>
        </div>
      </div>
    </Link>
  );
};

export default MobileBox;
