import {useContext, useEffect, useState} from "react";


// Images
import closeModal from "../assets/images/icons/close-modal.svg";



// FakeDB
import emoji from "../db/emoji";



// Context
import {MainContext} from "../utils/MainContext";

// Yup
import {object, string} from "yup";

// React hook form
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";

// Translation
import {useTranslation} from "react-i18next";

import * as DashboardService from "../Modules/Dashboard/Service/DashboardService";

const EditBoxNameModal = () => {
  // Translation
  const { t } = useTranslation();


  
  // Local states
  const [emojiActive, setEmojiActive] = useState({
    codes: "1F603",
    char: "😃",
  });


    // Select emoji
    const selectEmoji = (emojiItem) => {
      if (emojiActive === emojiItem.codes) {
        setEmojiActive(null);
      } else {
        setEmojiActive(emojiItem);
      }
    };



  // Global states
  const { editBoxOpen, editBoxId, setEditBoxOpen, boxes, setBoxes } =
    useContext(MainContext);

  // Validate modal input
  const inputSchema = object({
    title: string()
      .required(t("editBoxName.errorMsg1"))
      .trim()
      .min(4, t("editBoxName.errorMsg2"))
      .max(16, t("editBoxName.errorMsg3")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(inputSchema),
  });

  const onSubmit = async (data) => {
    try {
      const res = await DashboardService.editBox(data, editBoxId);
      window.location.reload();
      reset();
      setEditBoxOpen(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (editBoxOpen) {
      const title = (boxes.find(box => box.id === editBoxId) || {}).title || '';
      setValue("title", title);
    }
  }, [editBoxOpen, editBoxId, boxes, setValue]);
  return (
    <div
      className={editBoxOpen ? "editBoxNameModal active" : "editBoxNameModal"}
    >
      <div className="modalOverlay" onClick={() => setEditBoxOpen(false)}></div>
      <div className="modalBox">
        <div className="seperator"></div>
        <div className="editModalHead">
          <h2 className="modalTitle">{t("editBoxName.title")}</h2>
          <div className="close" onClick={() => setEditBoxOpen(false)}>
            <img src={closeModal} alt="close-icon" />
          </div>
        </div>
        <ul className="emojiList">
          {emoji.map((item) => (
            <li
              className={
                emojiActive.codes === item.codes
                  ? "emojiItem isActive"
                  : "emojiItem"
              }
              key={item.codes}
              onClick={() => selectEmoji(item)}
            >
              {item.char}
            </li>
          ))}
        </ul>
        <form className="modalForm" onSubmit={handleSubmit(onSubmit)}>
          <input
            className="modalInput"
            type="text"
            name="title"
            // value={(boxes.find(box => box.id === editBoxId) || {}).title || ''}
            placeholder={t("editBoxName.placeholder")}
            {...register("title")}
          />
          {errors.title && (
            <span className="errorMessage">{errors.title.message}</span>
          )}
          <button type="submit" className="addBox">
            {t("editBoxName.btnValue")}
          </button>
          <button type="submit" className="deleteBox">Delete Box</button>
        </form>
      </div>
      <div className="closeModal" onClick={() => setEditBoxOpen(false)}>
        <img src={closeModal} alt="close-icon" />
        <span>{t("editBoxName.close")}</span>
      </div>
    </div>
  );
};

export default EditBoxNameModal;
