import React, { useState, useEffect } from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";
import infoIcon from "../../../../assets/images/icons/info-circle-gray.svg";
import addCircle from "../../../../assets/images/icons/add-circle.svg";
import Close from "../../../../assets/images/icons/close-square.svg";
import { v4 as uuidv4 } from "uuid";
import Spinner from "../../../../components/Spinner";
import * as DictionaryService from "../../../Dictionaries/Service/DictionaryService";
import { toastMsg } from "../../../../utils/swal";
import Select from "react-select";

const ProfileKeywordEdit = ({ profileData, closeModal, setProfileData }) => {
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState(profileData?.keywords || []);
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    const getKeywordsList = async () => {
      setLoading(true);
      try {
        const res = await DictionaryService.allKeywords();
        setKeywords(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getKeywordsList();
  }, []);

  // const addNewKeyword = () => {
  //   setProfileInfo((prev) => ({
  //     ...prev,
  //     keywords: [...prev.keywords, { id: uuidv4(), value: "" }],
  //   }));
  // };

  const removeKeyword = async (keyword) => {
    setLoading(true);
    try {
      if (keyword.id && profileData.keywords.length === profileInfo.length) {
        const res = await ProfileService.deleteProfileDetail({
          field_name: "keyword",
          id: keyword,
        });
        setProfileData((prev) => ({
          ...prev,
          keywords: res.data.keywords,
        }));
        setProfileInfo((prev) => [...prev, ...res.data.keywords]);
      } else {
        setProfileInfo((prev) => [
          ...prev,
          ...prev.filter((item) => item !== keyword),
        ]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // const handleInputChange = (value, keyword) => {
  //   const updatedKeywords = profileInfo.keywords.map((item) => {
  //     if (item.id === keyword.id) {
  //       return { ...item, value };
  //     }
  //     return item;
  //   });
  //   setProfileInfo((prev) => ({
  //     ...prev,
  //     keywords: updatedKeywords,
  //   }));
  // };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit({ keywords: profileInfo });
      setProfileData(res);
      setProfileInfo((prev) => [...prev, ...res]);
      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileKeywordsEdit">
      <div className="editSkillsArea">
        {profileInfo.map((keyword, index) => (
          <button key={index} className="skillsCaption">
            {keyword}
            <span onClick={() => removeKeyword(keyword)}>x</span>
          </button>
        ))}
      </div>
      <div className="addSkillsArea">
        <Select
          placeholder={"Seçin..."}
          closeMenuOnSelect={true}
          escapeClearsValue={true}
          options={keywords}
          onChange={(selected) => {
            const existing = profileInfo.find(
              (item) => item === selected.value
            );
            if (existing) {
              toastMsg(
                "Bu açar söz artıq əlavə olunub !",
                "#ffffff",
                "#000000"
              );
            } else {
              setProfileInfo((prev) => [...prev, selected.value]);
            }
          }}
          isDisabled={profileInfo.length >= 20}
        />
        {/* {profileInfo.languages.map((language, index) => (
            <div key={language.id} className="socialInp">
              <CustomInput
                placeholder={"Type"}
                rightIcon={Arrow}
                onChange={(value) => handleInputChange(index, value)}
                value={language.name}
              />
              <span onClick={() => removeInp(language)}>x</span>
            </div>
          ))} */}
        <div className="infoSkills">
          <img src={infoIcon} alt="Info Icon" />
          Sadəcə 20 açar söz əlavə edə bilərsiniz.
        </div>
      </div>
      {/* <form className="infoKeywordsForm">
        {profileInfo.keywords.map((keyword) => (
          <div className="keywordInput" key={keyword.id}>
            <CustomInput
              placeholder={"Type keyword"}
              onChange={(value) => handleInputChange(value, keyword)}
              value={keyword.value}
            />
            <span onClick={() => removeKeyword(keyword)} className="deleteBtn">
              <img src={Close} alt="close_icon" />
            </span>
          </div>
        ))}
      </form> */}
      <div className="btnGroup">
        {/* <div className="addBtn">
          <button className="btnAdd" type="button" onClick={addNewKeyword}>
            <img src={addCircle} alt="add_icon" />
            Add New
          </button>
        </div> */}
        <div className="editBtn">
          <button
            className="btn cancel"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            İmtina
          </button>
          <button className="btn" onClick={onSubmit}>
            Yadda saxla
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileKeywordEdit;
