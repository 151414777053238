const searchKeywords = [
  {
    id: 1,
    keyword: "New Startup in on way!",
  },
  {
    id: 2,
    keyword: "Creative",
  },
  {
    id: 3,
    keyword: "UX/UI Design",
  },
  {
    id: 4,
    keyword: "Back-End",
  },
  {
    id: 5,
    keyword: "Front-End",
  },
  {
    id: 6,
    keyword: "Creative",
  },
  {
    id: 7,
    keyword: "UX Design",
  },
  {
    id: 8,
    keyword: "UI Design",
  },
  {
    id: 9,
    keyword: "Product Design",
  },
  {
    id: 10,
    keyword: "Azerbaijan",
  },
];

export default searchKeywords;
